<template>
  <div class="wallent">
    <van-nav-bar
        title=""
        left-arrow
        right-text="余额明细"
        @click-left="onClickLeft"
        @click-right="onClickRight"
    />
    <van-notice-bar
      v-show="show"
      wrapable
      :scrollable="false"
      color="#ffffff"
      background="#EE0A24"
      left-icon="warning-o"
      @click="rechargehistory"
    >
      {{reason}}
    </van-notice-bar>
    <div class="wallent_content" :style="'margin-top: ' + (screenWidth * 0.1) + 'px;'">
      <van-icon name="gold-coin" color="#FFC300" size="60" />
      <van-cell :border="false" title="我的余额" title-class="content_text" />
      <van-cell :border="false" title-class="content_money">
        <template #title>
          <span>&yen;</span>{{userinfo.credit2}}
        </template>
      </van-cell>
      <van-cell-group :border="false" style="margin-top: 20px;">
        <van-cell :border="false">
          <template #title>
            <div class="chognzhi_tishi">充值金额必须大于500元</div>
          </template>
        </van-cell>
        <van-cell :border="false" v-if="false">
          <template #title>
            <van-button type="primary" size="small" @click="wxchongzhi">微信充值</van-button>
          </template>
        </van-cell>
        <van-cell :border="false">
          <template #title>
            <van-button type="info" size="small" @click="zfbchongzhi">支付宝充值</van-button>
          </template>
        </van-cell>
        <van-cell :border="false" v-if="false">
          <template #title>
            <van-button type="default" size="small" @click="yhkchongzhi">银行转账</van-button>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="shili">
        <div class="shili_title">转账截图实例:</div>
        <van-cell :border="false">
          <template #title>
            <div class="shili_item_title">支付宝</div>
            <van-image
              src="https://img.midbest.cn/uploads/image/chongzhi/zfb.png"
              width="100px"
              height="100px"
              @click="yulan(0)"
              fit="cover"
              lazy-load
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </template>
          <template #default>
            <div class="shili_item_title">银行卡</div>
            <van-image
              src="https://img.midbest.cn/uploads/image/chongzhi/yhk.png"
              width="100px"
              height="100px"
              @click="yulan(1)"
              fit="cover"
              lazy-load
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </template>
        </van-cell>
      </div>
      <div class="chongzhi_title">
        <van-cell :border="false" is-link @click="rechargehistory">
          <template #title>
            充值记录
          </template>
        </van-cell>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
export default {
  name: "Wallent",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      iswx: false,
      islogin: false,
      userinfo: {},
      show: false, // 错误提示显示
      reason: "有充值申请被驳回，请到充值记录查看详情", // 错误提示内容
    }
  },
  mounted() {
    this.islogin = localStorage.getItem("DK_ISLOGIN") ? true : false;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "充值");
    this.getinfo();
  },
  methods: {
    // 获取用户余额
    getinfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(_that.$store.state.domain + "web/user/getMoney")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.userinfo = res.data.data;
            _that.show = _that.userinfo.show_fail;
          }else {
            _that.$toast(res.data.msg ? res.data.msg : "获取数据失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 跳转到余额列表页
    onClickRight() {
      this.$router.push({ name: "RechargeList", query: {shareid: this.shareid, merchid: this.merchid} });
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 微信充值
    wxchongzhi() {
      this.$router.push({ name: "Recharge", query: {shareid: this.shareid, merchid: this.merchid} });
    },
    // 支付宝充值
    zfbchongzhi() {
      this.$router.push({ name: "RechargePic", query: {type: "ZFB", shareid: this.shareid, merchid: this.merchid} });
    },
    // 银行卡转账
    yhkchongzhi() {
      this.$router.push({ name: "RechargePic", query: {type: "YHK", shareid: this.shareid, merchid: this.merchid} });
    },
    // 充值记录
    rechargehistory() {
      this.$router.push({ name: "RechargeHistory", query: {shareid: this.shareid, merchid: this.merchid} });
    },
    // 预览实例图
    yulan(index) {
      ImagePreview({
        images: [
          'https://img.midbest.cn/uploads/image/chongzhi/zfb.png',
          'https://img.midbest.cn/uploads/image/chongzhi/yhk.png',
        ],
        startPosition: index,
      });
    },
  }
}
</script>
<style lang="less">
.wallent {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
    .van-nav-bar__text {
      color: #333;
    }
  }
  .wallent_content{
    padding-bottom: 60px;
    overflow: hidden;
    .van-cell {
      .van-cell__title {
        flex: 1;
        text-align: center;
      }
      .chognzhi_tishi {
        color: #f00;
        font-size: 14px;
        font-weight: bold;
      }
      .content_text {
        padding-top: 20px;
        color: #333;
        font-size: 16px;
      }
      .content_money {
        color: #333;
        font-size: 40px;
        span {
          font-size: 20px;
          margin-right: 5px;
        }
      }
    }
    .van-cell-group {
      .van-cell {
        .van-button {
          width: 200px;
          height: 46px;
        }
      }
    }
    .chongzhi_title {
      width: 110px;
      margin: 10px auto 0;
      .van-cell {
        color: #1989FA;
        .van-cell__right-icon {
          color: #1989FA;
        }
      }
    }
    .shili {
      padding: 0 20px;
      margin-top: 10px;
      .van-cell__title,
      .van-cell__value {
        flex: 1;
        text-align: center;
        color: #333;
      }
      .shili_title {
        font-size: 14px;
        padding: 0 16px;
        // text-align: left;
      }
      .shili_item {
        color: #333;
        text-align: left;
      }
    }
  }
}
</style>